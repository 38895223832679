import React, { FC } from 'react';
import DynamicField, { DynamicFieldType } from '../domain/DynamicField';
import FormField from '../../../components/FormField';
import Input from '../../../ui/Input';
import { RichSelect, Select } from '../../../ui';

export interface FormDynamicFieldProps {
  field: DynamicField;
  namePrefix?: string;
}

function prefixedName(name: string, prefix?: string) {
  return prefix ? `${prefix}.${name}` : name;
}

const FormDynamicField: FC<FormDynamicFieldProps> = ({
  namePrefix = 'dynamic',
  field,
}) => {
  let input = <Input />;

  if (field.type === DynamicFieldType.NUMBER) {
    input = <Input type="number" />;
  }

  if (field.type === DynamicFieldType.SELECT) {
    input = (
      <Select nullable>
        {field.options?.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </Select>
    );
  }

  if (field.type === DynamicFieldType.MULTISELECT) {
    input = (
      <RichSelect
        simpleValue
        multiple
        options={(field.options || []).map((value) => ({
          value,
          label: value,
        }))}
      />
    );
  }

  return (
    <FormField
      key={field.name}
      label={field.label}
      name={prefixedName(field.name, namePrefix)}
      required={field.required}
      helperText={field.description}
    >
      {input}
    </FormField>
  );
};

export default FormDynamicField;
