import React from 'react';
import Organization from '../domain/Organization';
import ConnectedManagedOrganizationDeleteDrawer from '../containers/ConnectedManagedOrganizationDeleteDrawer';
import OrganizationRelation, {
  OrganizationRelationType,
} from '../domain/OrganizationRelation';
import ManagedOrganizationCard from './ManagedOrganizationCard';
import PlusIcon from '../ui/icons/PlusIcon';
import Tag from '../domain/Tag';
import { t } from '../i18n';
import ConnectedManagedOrganizationDeactivateDrawer from '../containers/ConnectedManagedOrganizationDeactivateDrawer';
import { Card, DataGrid, HStack, Image, Link, TextLink } from '../ui';
import { getHumanReadableOrganizationRelationType } from '../util/organization';
import PriorityIcon from '../ui/icons/PriorityIcon';
import ManagedOrganizationActions from './ManagedOrganizationActions';
import { getCustomTagContexts } from '../util/tag';
import ListTransformer from '../lib/ListTransformer';

export interface ManagedOrganizationListProps {
  organizations: Organization[];
  relations: OrganizationRelation[];
  editable?: boolean;
  tags: Tag[];
}

function mergeOrganizationsAndRelations(
  organizations: Organization[],
  relations: OrganizationRelation[],
) {
  return organizations
    .map((organization) => {
      const relation = relations.find(
        (relation) => relation.relatedOrganizationId === organization.id,
      );

      return {
        organization,
        relation: relation || new OrganizationRelation(),
      };
    })
    .filter(Boolean);
}

const ManagedOrganizationList: React.FC<ManagedOrganizationListProps> = ({
  organizations,
  relations,
  editable,
  tags,
}) => {
  const items = mergeOrganizationsAndRelations(organizations, relations);
  const customTags = getCustomTagContexts(tags);

  return (
    <div aria-label="Clients">
      <ConnectedManagedOrganizationDeleteDrawer name="managed-organization.delete" />
      <ConnectedManagedOrganizationDeactivateDrawer name="managed-organization.deactivate" />

      <DataGrid
        title={t('Clients')}
        data={items}
        fields={{
          name: {
            title: t('Name'),
            accessor: (item) => ({
              value: item.organization.name,
              node: (
                <Link to={`/clients/view/${item.organization.id}`}>
                  <HStack>
                    {item.organization.logo && (
                      <Image
                        maxH={20}
                        maxW={20}
                        src={item.organization.logo}
                        alt={item.organization.name}
                      />
                    )}
                    <TextLink>{item.organization.name}</TextLink>
                  </HStack>
                </Link>
              ),
            }),
          },
          priority: {
            title: t('Priority'),
            accessor: (item) => ({
              value: item.relation.priority ? '+' : '',
              node: !!item.relation.priority && <PriorityIcon />,
            }),
            hiddenInExport: true,
          },
          type: {
            title: t('Type'),
            accessor: (item) =>
              getHumanReadableOrganizationRelationType(
                item.relation.relationType,
              ),
          },
          dateStart: {
            title: t('Start date'),
            accessor: (item) => item.relation.dateStart,
            hidden: true,
          },
          dateEnd: {
            title: t('End date'),
            accessor: (item) => item.relation.dateEnd,
            hidden: true,
          },
          actions: {
            hidden: !editable,
            hiddenInExport: true,
            accessor: ({ organization, relation }) => (
              <ManagedOrganizationActions
                organization={organization}
                organizationRelation={relation}
              />
            ),
          },
        }}
        wrapper={Card}
        filters={[
          {
            key: 'type',
            label: t('Type'),
            options: [
              {
                value: OrganizationRelationType.CLIENT,
                label: getHumanReadableOrganizationRelationType(
                  OrganizationRelationType.CLIENT,
                ),
              },
              {
                value: OrganizationRelationType.SALESLEAD,
                label: getHumanReadableOrganizationRelationType(
                  OrganizationRelationType.SALESLEAD,
                ),
              },
              {
                value: OrganizationRelationType.PARTNER,
                label: getHumanReadableOrganizationRelationType(
                  OrganizationRelationType.PARTNER,
                ),
              },
              {
                value: OrganizationRelationType.SPONSOR,
                label: getHumanReadableOrganizationRelationType(
                  OrganizationRelationType.SPONSOR,
                ),
              },
            ],
            apply: (value, item) => item.relation.relationType === value,
          },
          {
            key: 'industry',
            label: t('Industry'),
            apply: (value, item) => item.organization.industry === value,
            options: [
              ...new ListTransformer(
                Array.from(
                  new Set(
                    organizations.map((org) => org.industry).filter(Boolean),
                  ),
                ),
              )
                .sortAlphabetically()
                .toArray()
                .map((p) => ({
                  label: p,
                  value: p,
                })),
            ],
          },
          {
            key: 'status',
            label: t('Status'),
            options: [
              {
                value: 'active',
                label: t('Active'),
                appliedByDefault: true,
              },
              {
                value: 'inactive',
                label: t('Inactive'),
              },
            ],
            apply: (value, item) =>
              value === 'active'
                ? item.relation.isOngoing()
                : !item.relation.isOngoing(),
          },
          ...customTags.map((tagContext) => {
            const contextTags = tags.filter(
              (tag) => tag.context === tagContext,
            );

            return {
              key: tagContext,
              label: tagContext,
              apply: (value: unknown, item: any) =>
                item.relation.tags?.includes(value),
              options: [
                ...new ListTransformer(contextTags)
                  .sortAlphabetically()
                  .toArray()
                  .map((p) => ({
                    label: p.name,
                    value: p.id,
                  })),
              ],
            };
          }),
        ]}
        grid={{
          render: ({ item: { organization, relation } }) => (
            <ManagedOrganizationCard
              key={organization.id}
              linkTo={`/clients/view/${organization.id}`}
              organization={organization}
              organizationRelation={relation}
              editable={editable}
              compact
            />
          ),
        }}
        actions={[
          editable && {
            label: t('Create client'),
            icon: <PlusIcon />,
            to: `?managed-organization`,
          },
        ]}
      />
    </div>
  );
};

export default ManagedOrganizationList;
