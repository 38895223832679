import React from 'react';
import Person from '../domain/Person';
import RichSelect, { RichSelectOption } from '../ui/RichSelect';
import PersonUser from './PersonUser';

export interface PersonSelectProps {
  persons: Person[];
  relatedOrganisationPersons?: Person[];
  value?: Person;
  onChange?: (personId: string) => void;
  nullable?: boolean;
  placeholder?: string;
  onCreate?: (name: string) => void;
}

const personToOption = (person: Person, hideOrg: boolean): RichSelectOption => {
  return {
    value: person.id,
    label: person.name,
    component: <PersonUser person={person} hideOrganization={hideOrg} />,
  };
};

const PersonSelect: React.FC<PersonSelectProps> = ({
  persons,
  relatedOrganisationPersons,
  value,
  onChange,
  nullable,
  placeholder,
  onCreate,
}) => {
  const options = [
    ...persons.map((elem) => personToOption(elem, true)),
    ...(relatedOrganisationPersons || []).map((elem) =>
      personToOption(elem, false),
    ),
  ];

  return (
    <RichSelect
      onCreate={onCreate}
      minHeight={50}
      options={options}
      onChange={(option) => {
        onChange?.((option as RichSelectOption)?.value || null);
      }}
      value={value && personToOption(value, true)}
      clearable={nullable}
      placeholder={placeholder}
    />
  );
};

export default PersonSelect;
