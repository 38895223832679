import React, { FC } from 'react';
import DynamicField from '../domain/DynamicField';
import { alphabeticallyOrderedFields } from '../utils';
import FormDynamicField from './FormDynamicField';

export interface FormDynamicFieldsProps {
  fields: DynamicField[];
  namePrefix?: string;
}

const FormDynamicFields: FC<FormDynamicFieldsProps> = ({
  namePrefix,
  fields,
}) => {
  return (
    <>
      {alphabeticallyOrderedFields(fields).map((field) => (
        <FormDynamicField field={field} namePrefix={namePrefix} />
      ))}
    </>
  );
};

export default FormDynamicFields;
