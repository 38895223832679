import React from 'react';
import { Role } from '../domain/Person';
import useResolver from '../hooks/useResolver';
import PersonStore from '../store/PersonStore';

interface DisplayForRoleProps {
  roles: Role[];
}

export const EDIT_ROLES: Role[] = [Role.MANAGER, Role.ADMIN];

const DisplayForRole: React.FC<DisplayForRoleProps> = ({ roles, children }) => {
  const { currentPerson } = useResolver(PersonStore);

  if (!currentPerson || !currentPerson.hasPermission(...roles)) {
    return null;
  }

  return <>{children}</>;
};

export default DisplayForRole;
