import React from 'react';
import Person, { Role } from '../domain/Person';
import SettingsIcon from '../ui/icons/SettingsIcon';
import IconedText from '../ui/IconedText';
import EditIcon from '../ui/icons/EditIcon';
import UserIcon from '../ui/icons/UserIcon';
import CloseIcon from '../ui/icons/CloseIcon';

export interface RoleBadgeProps {
  person: Person;
}

const PersonRole: React.FC<RoleBadgeProps> = ({ person }) => {
  const icons: {
    [key in Role]: any;
  } = {
    [Role.ADMIN]: <SettingsIcon color="orange.500" />,
    [Role.MANAGER]: <EditIcon color="cyan.500" />,
    [Role.USER]: <UserIcon color="brand.500" />,
    [Role.DISABLED]: <CloseIcon color="gray.500" />,
  };

  return (
    <IconedText icon={icons[person.role]}>
      {person.humanReadableRole}
    </IconedText>
  );
};

export default PersonRole;
