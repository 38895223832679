import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import useResolver from '../hooks/useResolver';
import PersonStore from '../store/PersonStore';
import PersonSelect, { PersonSelectProps } from '../components/PersonSelect';
import Person, { ACTIVE_ROLES, Role } from '../domain/Person';
import useToast from '../hooks/useToast';
import OrganizationRelationStore from '../store/OrganizationRelationStore';
import OrganizationStore from '../store/OrganizationStore';
import { t } from '../i18n';
import PersonsSelect from '../components/PersonsSelect';
import { RichSelectOption } from '../ui/RichSelect';

export interface ConnectedOrganizationPersonSelectProps
  extends Omit<PersonSelectProps, 'persons' | 'currentPerson' | 'value'> {
  roles?: Role[];
  excludeCurrentPerson?: boolean;
  value?: string;
  includeRelatedOrganizations?: boolean;
  multiple?: boolean;
  onSelect?: (option: RichSelectOption[]) => void;
}

const ConnectedOrganizationPersonSelect: React.FC<
  ConnectedOrganizationPersonSelectProps
> = ({
  roles = ACTIVE_ROLES,
  excludeCurrentPerson,
  value,
  includeRelatedOrganizations,
  multiple,
  onSelect,
  ...props
}) => {
  const personStore = useResolver(PersonStore);
  const organizationRelationStore = useResolver(OrganizationRelationStore);
  const organizationStore = useResolver(OrganizationStore);
  const toast = useToast();
  const relatedOrganizationsIds =
    organizationRelationStore.currentOrganizationRelations.map(
      (org) => org.relatedOrganizationId,
    );

  useEffect(() => {
    personStore.loadCurrentOrganizationPersons();
    organizationRelationStore.loadByCurrentOrganization();
    includeRelatedOrganizations &&
      personStore.loadCurrentAndRelatedOrganizationPersons();
  }, []);

  let persons: Person[] = personStore.currentOrganizationPersons;
  let relatedOrganisationPersons: Person[] = [];

  if (includeRelatedOrganizations) {
    relatedOrganisationPersons = personStore.getOrganizationsPersons(
      relatedOrganizationsIds.filter((relatedOrganizationId) => {
        return organizationRelationStore
          .getFilteredItems({
            organizationId: organizationStore.currentOrganizationId,
            relatedOrganizationId,
          })
          .some((relatedOrg) => relatedOrg.isOngoing());
      }),
    );

    relatedOrganisationPersons.forEach((p) => {
      p.organization = organizationStore.getByIdOrLoad(p.organizationId);
    });
  }

  if (roles) {
    persons = persons.filter((p) => p.hasPermission(...roles));
    relatedOrganisationPersons = relatedOrganisationPersons.filter((p) =>
      p.hasPermission(...roles),
    );
  }

  if (excludeCurrentPerson) {
    persons = persons.filter((p) => p.id !== personStore.currentPerson?.id);
  }

  const onCreate = async (name: string) => {
    try {
      const person = await personStore.create({
        name,
        organizationId: personStore.currentPerson?.organizationId,
      });

      props.onChange?.(person.id);
    } catch (err: Error | any) {
      toast({ status: 'error', description: t('Something went wrong') });
    }
  };

  if (multiple) {
    return (
      <PersonsSelect
        persons={persons}
        relatedOrganisationPersons={relatedOrganisationPersons}
        onCreate={onCreate}
        person={value && !multiple && personStore.getByIdOrLoad(value)}
        onSelect={onSelect && onSelect}
        {...props}
      />
    );
  }

  return (
    <PersonSelect
      persons={persons}
      relatedOrganisationPersons={relatedOrganisationPersons}
      onCreate={onCreate}
      value={value && personStore.getByIdOrLoad(value)}
      {...props}
    />
  );
};

export default observer(ConnectedOrganizationPersonSelect);
