import React from 'react';
import { MenuItem } from '../ui/Menu';
import EditIcon from '../ui/icons/EditIcon';
import Link from '../ui/Link';
import DeleteIcon from '../ui/icons/DeleteIcon';
import MoreActionsMenu from '../ui/MoreActionsMenu';
import Project, { ProjectState } from '../domain/Project';
import CheckIcon from '../ui/icons/CheckIcon';
import CloseIcon from '../ui/icons/CloseIcon';
import CoffeeIcon from '../ui/icons/CoffeeIcon';
import ArrowIcon from '../ui/icons/ArrowIcon';
import { useTranslation } from 'react-i18next';
import { getHumanReadableProjectType } from '../util/project';

export interface ProjectMoreActionsMenuProps {
  project: Project;
  onStateChange: (newState: ProjectState) => void;
  onEnd: (date: Date) => void;
}

const ProjectMoreActionsMenu: React.FC<ProjectMoreActionsMenuProps> = ({
  project,
  onStateChange,
  onEnd,
}) => {
  const { t } = useTranslation();

  return (
    <MoreActionsMenu label={t('Project actions')}>
      <MenuItem
        preserveQuery
        icon={<EditIcon />}
        as={Link}
        to={`?${project.type.toLowerCase()}=${project.id}`}
      >
        Edit
      </MenuItem>

      {project.isDraft() && (
        <MenuItem
          icon={<CheckIcon />}
          onClick={() => onStateChange(ProjectState.PUBLISHED)}
        >
          Publish
        </MenuItem>
      )}

      {project.isOngoing() && (
        <MenuItem
          icon={<ArrowIcon direction="right" />}
          to={`?${getHumanReadableProjectType(project.type)}.end=${project.id}`}
        >
          {t('End now')}
        </MenuItem>
      )}

      {!project.isPublished() && (
        <MenuItem
          preserveQuery
          icon={<DeleteIcon />}
          as={Link}
          to={`?${getHumanReadableProjectType(project.type)}.delete=${
            project.id
          }`}
        >
          {t('Delete')}
        </MenuItem>
      )}

      {project.isPublished() && (
        <MenuItem
          icon={<CoffeeIcon />}
          onClick={() => onStateChange(ProjectState.DRAFT)}
        >
          {t('Unpublish')}
        </MenuItem>
      )}

      {project.isPublished() && (
        <MenuItem
          icon={<CloseIcon />}
          onClick={() => onStateChange(ProjectState.ARCHIVED)}
        >
          {t('Archive')}
        </MenuItem>
      )}

      {project.isArchived() && (
        <MenuItem
          icon={<CheckIcon />}
          onClick={() => onStateChange(ProjectState.PUBLISHED)}
        >
          {t('Republish')}
        </MenuItem>
      )}
    </MoreActionsMenu>
  );
};

export default ProjectMoreActionsMenu;
