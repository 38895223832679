import React, { ReactNode } from 'react';
import ReactSelect, { components, Props } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { t } from '../i18n';

const MultiValueLabel: React.FC<any> = (props) => {
  return (
    <components.MultiValueLabel {...props}>
      {props.data.component || props.data.label}
    </components.MultiValueLabel>
  );
};

const SingleValue: React.FC<any> = (props) => {
  return (
    <components.SingleValue {...props}>
      {props.data.component || props.data.label}
    </components.SingleValue>
  );
};

const Option: React.FC<any> = (props) => {
  return (
    <components.Option {...props}>
      {props.data.component || props.children}
    </components.Option>
  );
};

export interface RichSelectOption {
  value: string;
  label: string;
  component?: React.ReactElement;
}

export interface RichSelectProps
  extends Omit<
    Props<RichSelectOption, boolean, any>,
    'isMulti' | 'options' | 'onChange' | 'isClearable'
  > {
  options: RichSelectOption[];
  multiple?: boolean;
  onCreate?: (inputValue: string) => void;
  validateCreate?: (inputValue: string) => undefined | false | null | string;
  onChange?: (value: RichSelectOption | RichSelectOption[]) => void;
  clearable?: boolean;
  minHeight?: number;
  dropdownIndicator?: boolean;
  simpleValue?: boolean;
  formatCreateLabel?: (value: string) => ReactNode;
}

function formatValue<T extends RichSelectOption | string>(
  value: T | T[],
  options: RichSelectOption[],
): any {
  if (Array.isArray(value)) {
    return value.map((v) => formatValue(v, options));
  }

  if (value === null || value === undefined) {
    return value;
  }

  if (typeof value === 'object' && 'value' in value) {
    return value;
  }

  return {
    value: value,
    label: options.find((o) => o.value === value)?.label || value,
  };
}

const RichSelect: React.FC<RichSelectProps> = ({
  options,
  multiple,
  onCreate,
  validateCreate,
  clearable,
  value,
  minHeight = 38,
  dropdownIndicator = true,
  id,
  simpleValue,
  onChange,
  ...props
}) => {
  const selectProps: Props<any, boolean, any> = {
    isMulti: multiple,
    components: { Option, MultiValueLabel, SingleValue },
    options,
    isClearable: clearable,
    onCreateOption: (inputValue: string) => {
      if (validateCreate?.(inputValue)) {
        return;
      }

      return onCreate && onCreate(inputValue);
    },
    styles: {
      dropdownIndicator: (base) => ({
        ...base,
        display: dropdownIndicator ? base.display : 'none',
      }),
      control: (base) => ({
        ...base,
        minHeight,
        backgroundColor: '#fff',
      }),
    },
    value: formatValue(value, options),
    formatCreateLabel: (inputValue: string) => {
      const validationError = validateCreate?.(inputValue);

      if (validationError) {
        return validationError;
      }

      return (
        <span>
          {t('Create')} "{inputValue}"
        </span>
      );
    },
    onChange: (value) => {
      if (value && simpleValue) {
        onChange?.(
          Array.isArray(value) ? value.map((v) => v.value) : value?.value,
        );
      } else {
        onChange?.(value);
      }
    },
    noOptionsMessage: () => t('No options'),
    inputId: id,
    ...props,
  };

  if (onCreate && !validateCreate?.(props.value)) {
    return <CreatableSelect {...selectProps} />;
  }

  return <ReactSelect {...selectProps} />;
};

RichSelect.defaultProps = {};

export default RichSelect;
