import React from 'react';
import { SimpleGrid, SimpleGridProps } from '@chakra-ui/react';

export interface AutoGridProps
  extends Omit<
    SimpleGridProps,
    'columns' | 'templateColumns' | 'minChildWidth'
  > {
  childWidth: string;
}

const AutoGrid: React.FC<AutoGridProps> = ({
  children,
  childWidth,
  ...props
}) => {
  return (
    <SimpleGrid
      templateColumns={`repeat(auto-fill, minmax(min(${childWidth}, 100%), 1fr))`}
      {...props}
    >
      {children}
    </SimpleGrid>
  );
};

AutoGrid.defaultProps = {};

export default AutoGrid;
