import React, { useEffect } from 'react';
import FormDynamicFields, { FormDynamicFieldsProps } from './FormDynamicFields';
import useResolver from '../../../hooks/useResolver';
import DynamicFieldStore from '../store/DynamicFieldStore';
import OrganizationStore from '../../../store/OrganizationStore';

export interface ConnectedFormDynamicFieldsProps
  extends Omit<FormDynamicFieldsProps, 'fields'> {
  tags: string[];
  organizationId?: string;
}

const ConnectedFormDynamicFields: React.FC<ConnectedFormDynamicFieldsProps> = ({
  tags,
  organizationId: suppliedOrganizationId,
  ...props
}) => {
  const dynamicFieldStore = useResolver(DynamicFieldStore);
  const organizationStore = useResolver(OrganizationStore);

  const organizationId =
    suppliedOrganizationId || organizationStore.currentOrganizationId;

  useEffect(() => {
    dynamicFieldStore.loadByOrganization(organizationId);
  }, []);

  return (
    <FormDynamicFields
      fields={dynamicFieldStore.organizationItemsByTags(organizationId, tags)}
      {...props}
    />
  );
};

export default ConnectedFormDynamicFields;
