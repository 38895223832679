import React from 'react';
import _ from 'lodash';
import Input from '../ui/Input';
import SimpleGrid from '../ui/SimpleGrid';
import Stack from '../ui/Stack';
import FormField from './FormField';
import { observer } from 'mobx-react';
import DrawerForm from './DrawerForm';
import Project, {
  PricingType,
  ProjectPeriod,
  ProjectState,
  ProjectType,
} from '../domain/Project';
import DateInput from '../ui/DateInput';
import FormImageField from './FormImageField';
import ConnectedProjectTagsField from '../containers/ConnectedProjectTagsField';
import ConnectedProjectPositionsField from '../containers/ConnectedProjectPositionsField';
import ConnectedOrganizationPersonSelect from '../containers/ConnectedOrganizationPersonSelect';
import { getProjectPricingTypeLabel, getTextsByType } from '../util/project';
import ConnectedManagedOrganizationSelect from '../containers/ConnectedManagedOrganizationSelect';
import Select from '../ui/Select';
import Checkbox from '../ui/Checkbox';
import {
  ConnectedFormDynamicFields,
  Resources,
} from '../features/dynamic-field';
import ConnectedProjectSelect from '../containers/ConnectedProjectSelect';
import { useTranslation } from 'react-i18next';
import PaymentScheduleFormFields from './PaymentScheduleFormFields';
import { Recurrence } from '../domain/PaymentSchedule';

export interface ProjectFormDrawerProps {
  name?: string;
  type?: ProjectType;
  onSubmit: (
    values: Partial<Project> & {
      tags?: any;
      positions?: any;
      paymentSchedule: {
        recurrence: Recurrence;
        dateStart: Date;
        offsetInMonths?: number;
      };
    },
    projectId: string,
  ) => void | Promise<void>;
  project?: Project;
  currencySymbol: string;
  recurrenceValues?: {
    recurrence: Recurrence;
    dateStart: Date;
    offsetInMonths?: number;
  };
}

export const DYNAMIC_FIELD_RESOURCE_MAP: { [key in ProjectType]: Resources[] } =
  {
    [ProjectType.PROJECT]: [Resources.PROJECTS],
    [ProjectType.EVENT]: [Resources.EVENTS],
    [ProjectType.TRAINING]: [Resources.TRAININGS],
  };

const ProjectFormDrawer: React.FC<ProjectFormDrawerProps> = ({
  name,
  type,
  onSubmit,
  project,
  currencySymbol,
  recurrenceValues,
}) => {
  const { t } = useTranslation();

  return (
    <DrawerForm
      size="xl"
      name={name}
      onSubmit={onSubmit}
      header={
        <>
          {project?.id ? t('Edit') : t('Create draft')}{' '}
          {getTextsByType(type).name.toLocaleLowerCase()}
        </>
      }
      defaultValues={{
        ...project,
        paymentSchedule: {
          ...recurrenceValues,
        },
      }}
    >
      {({ formContext: { watch } }) => {
        const { pricingType, paymentSchedule } = watch();
        const datesIncludeTime = !!watch('datesIncludeTime');

        return (
          <SimpleGrid minChildWidth="300px" spacing={4}>
            <Stack spacing={4}>
              <FormImageField name="image" label={t('Cover image')} />

              <FormField name="name" required label={t('Name')}>
                <Input size="lg" autoFocus />
              </FormField>

              <FormField
                name="relatedOrganizationId"
                label={getTextsByType(type).client}
              >
                <ConnectedManagedOrganizationSelect />
              </FormField>

              {type !== ProjectType.PROJECT && (
                <FormField
                  name="relatedToProjectId"
                  label={t('Related project')}
                >
                  <ConnectedProjectSelect
                    state={ProjectState.PUBLISHED}
                    period={[ProjectPeriod.ONGOING]}
                  />
                </FormField>
              )}

              <FormField
                name="ownerPersonId"
                label={getTextsByType(type).owner}
                required
              >
                <ConnectedOrganizationPersonSelect />
              </FormField>

              <FormField name="priority">
                <Checkbox>{t('High priority')}</Checkbox>
              </FormField>

              <FormField name="datesIncludeTime">
                <Checkbox>{t('Dates include time')}</Checkbox>
              </FormField>

              <FormField
                name="dateStart"
                label={t('Starts at')}
                required={PricingType.BUDGET === pricingType}
              >
                <DateInput withTime={datesIncludeTime} />
              </FormField>

              <FormField
                name="dateEnd"
                label={t('Ends at')}
                required={PricingType.BUDGET === pricingType}
              >
                <DateInput
                  withTime={datesIncludeTime}
                  min={watch('dateStart')}
                />
              </FormField>

              <FormField name="pricingType" label={t('Pricing type')}>
                <Select>
                  {_.values(PricingType).map((type) => (
                    <option key={type} value={type}>
                      {getProjectPricingTypeLabel(type)}
                    </option>
                  ))}
                </Select>
              </FormField>

              {PricingType.BUDGET === pricingType && (
                <FormField name="budget" label={t('Potential income')} required>
                  <Input
                    placeholder={currencySymbol}
                    type="number"
                    step="any"
                    min={0}
                  />
                </FormField>
              )}

              {PricingType.POTENTIAL_COST === pricingType && (
                <FormField name="budget" label={t('Potential cost')} required>
                  <Input
                    placeholder={currencySymbol}
                    type="number"
                    step="any"
                    min={0}
                  />
                </FormField>
              )}

              <PaymentScheduleFormFields
                recurrence={paymentSchedule.recurrence}
                project={project}
              />

              <ConnectedProjectTagsField projectId={project?.id} />

              <ConnectedFormDynamicFields
                tags={DYNAMIC_FIELD_RESOURCE_MAP[type]}
              />
            </Stack>
            <Stack>
              <ConnectedProjectPositionsField projectId={project?.id} />
            </Stack>
          </SimpleGrid>
        );
      }}
    </DrawerForm>
  );
};

ProjectFormDrawer.defaultProps = {
  type: ProjectType.PROJECT,
};

export default observer(ProjectFormDrawer);
